.aboutMainDiv {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.aboutMainDivSub {
  width: 85%;
  height: auto;
}

.aboutMainDivSec1 {
  height: auto;
  width: 100%;
  display: flex;
  align-items: start;
  flex-direction: column;
  gap: 5%;
}

.aboutMainDivSec1>div,
.aboutMainDivSec1>span {
  margin-bottom: 3%;
  /* Add margin-bottom to create a gap between each element */
}

.aboutMainDivRoute {
  color: rgb(0, 0, 0);
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0%;
  text-align: left;
}

.aboutMainDivHead {
  color: #38b772;
  font-size: 45px;
  font-weight: 700;
  text-align: left;
}

.aboutMainDivHeadContent {
  color: #879b8f;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  line-height: 30px;
}

.aboutMainImageDiv {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.aboutMainImageDivSub {
  height: 100%;
  width: 30%;
}

.aboutMainDivSec2 {
  height: auto;
  width: 100%;
  display: flex;
  align-items: start;
  flex-direction: column;
  gap: 5%;
}

.aboutMainDivSec2>div,
.aboutMainDivSec2>span {
  margin-bottom: 3%;
  /* Add margin-bottom to create a gap between each element */
}

.aboutMainDivHeadContentDiv {
  display: flex;
  flex-direction: column;
}

.aboutMainDivSec3 {
  height: auto;
  width: 100%;
  display: flex;
  align-items: start;
  flex-direction: column;
  gap: 5%;
}

.aboutMainDivSec3>div,
.aboutMainDivSec3>span {
  margin-bottom: 3%;
  /* Add margin-bottom to create a gap between each element */
}

.aboutMainDivSec4 {
  height: auto;
  width: 100%;
  display: flex;
  align-items: start;
  flex-direction: column;
  gap: 5%;
}

.aboutMainDivSec4>div,
.aboutMainDivSec4>span {
  margin-bottom: 3%;
  /* Add margin-bottom to create a gap between each element */
}

.homeGridRow {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px;
  background-color: #38b772;
  border-radius: 12px;
}
.homGridColumn {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
}
.homeGridTitle {
  font-size: 35px;
  font-weight: 700;
  color: #fff;
}
.homeGridSubTitle {
  font-size: 25px;
  font-weight: 400;
  color: #fff;
}
.aboutMainDivHeadContent2 {
  color: rgb(0, 0, 0);
  font-size: 20px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0%;
  text-align: left;
}

.aboutMainDivHead2 {
  color: rgb(0, 0, 0);
  font-size: 25px;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: 0%;
  text-align: left;
}

.dotHeadAbout {
  color: rgb(0, 0, 0);
  font-size: 48px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0%;
  text-align: left;
}

@media only screen and (max-width: 767px) {
  .aboutMainDivHeadContent2 {
    font-size: 16px;
  }

  .aboutMainDivHead2 {
    font-size: 19px;
  }
}

@media (max-width: 576px) {
  .aboutMainDivHead {
    font-size: 35px;
  }

  .homeGridRow {
    flex-direction: column;
    justify-content: center;
    padding: 5px;
  }
  .homeGridTitle {
    font-size: 25px;
    font-weight: 500;
  }
  .homeGridSubTitle {
    font-size: 18px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {}