.homeMainImgDiv {

  /* background-color: #d4d4d6  ; */
}

@media (max-width:768px){
  .homeMainImgDiv {
    background-size: cover;
    height: auto;
  }
}

@media only screen and (max-width: 576px) {
  .homeBannerImage {
    width: auto;
  }
}

.homeMainImgSupport {
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: start;
  position: absolute;
  cursor: pointer;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Apply animation to the image */
.supportAnimation {
  animation: fadeIn 5s ease-out forwards;
}

.homeViewMore {
  width: 103px;
  height: 30px;
  border-radius: 7px;
  background: rgb(16, 16, 16);
  color: rgb(255, 255, 255);
  font-size: 13px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 46%;
  cursor: pointer;
  position: relative;
  animation: fadeIn 1s ease-out forwards;
}

.homeViewMore:hover {
  color: rgb(255, 154, 0);
}

.homeLogoDiv {
  width: 100%;
  height: 45vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-row {
  animation: slideIn 5s ease forwards;
}
.homeLogoDivSub {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 20px 0;
}

.homeLogoDivSubCol1 {
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  gap: 5%;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  width: 30%;
  height: 200px;
}
@media only screen and (max-width: 768px) {
  .homeLogoDivSub {
    flex-direction: column;
    align-items: center;
  }
  .homeLogoDivSubCol1 {
    width: 100%;
  }
}
.homeCol1Ecclipse {
  /* width: 101px;
  height: 101px;
  background: rgb(255, 255, 255);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center; */
}

.homeCol1Head {
  color: rgb(0, 0, 0);
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0%;
  text-align: center;
}

.homeAboutUsDiv {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  gap: 20px;
  padding: 20px 0;
}

.homeAboutUsDivSub {
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 5%;
}
.homeAboutUsTitle {
  font-size: 20px;
  color: #206032;
  text-align: left;
}
.homeAboutUsHead {
  color: rgb(0, 0, 0);
  font-size: 35px;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: 0%;
  text-align: left;
}
.text-aeron {
  color: #38b772;
  font-size: 35px;
  font-weight: 600;
  line-height: 48px;
}

.homeAboutUsHeadSub {
  color: #879b8f;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0%;
  text-align: left;
}

.aeronSideAboutImg {
  height: 60%;
  width: 30%;
}
.homeGridRow {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px;
  background-color: #38b772;
  border-radius: 12px;
}
.homGridColumn {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
}
.homeGridTitle {
  font-size: 35px;
  font-weight: 700;
  color: #fff;
}
.homeGridSubTitle {
  font-size: 25px;
  font-weight: 400;
  color: #fff;
}
@media (max-width: 576px) {
  .homeGridRow {
    flex-direction: column;
    justify-content: center;
    padding: 5px;
  }
  .homeGridTitle {
    font-size: 25px;
    font-weight: 500;
  }
  .homeGridSubTitle {
    font-size: 18px;
  }
}
@media (max-width: 768px) {
  .homeAboutUsDiv {
    flex-direction: column;
    justify-content: center;
  }
  .aeronSideAboutImg {
    width: 50%;
  }
}
.homeServiceTitle {
  font-size: 45px;
  font-weight: 700;
  color: #38b772;
  text-align: center;
}
@media (max-width: 576px) {
  .homeServiceTitle {
    font-size: 35px;
  }
}
.homeServiceSubTitle {
  font-size: 16px;
  font-weight: 400;
  color: #879b8f;
  text-align: center;
}
.homeServiceRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  margin-top: 60px;
}
.homeOurTeam {
  padding: 20px;
  margin-top: 60px;
}
.homeTeamTitle {
  font-size: 45px;
  font-weight: 700;
  color: #38b772;
  text-align: center;
}
@media (max-width: 576px) {
  .homeTeamTitle {
    font-size: 35px;
  }
}
.homeTeamSubTitle {
  font-size: 16px;
  font-weight: 400;
  color: #879b8f;
  text-align: center;
}
.homeTeamRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 25px;
  flex-wrap: wrap;
  margin-top: 60px;
}
.homeBoxDiv {
  width: 100%;
  padding: 20px;
  background:#171f32;
  color: #fff;
  gap: 20px;
  margin-top: 60px;
  border-radius: 12px;
  position: relative;
}
.homeBoxColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  width:70%;
}
@media (max-width: 576px) {
  .homeBoxColumn {
    width: 100%;
  }
}
.homeBoxTitle {
  font-size: 25px;
  font-weight: 700;
  color: #fff;
  text-align: left;
}
.homeBoxSub {
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  text-align: left;
}
.homeBoxButton {
  display: flex;
  flex-direction: row;
  gap: 15px;
  justify-content: center;
  border: 1px solid #fff;
  border-radius: 12px;
  background-color: transparent;
  font-size: 16px;
  color: #fff;
  width: fit-content;
  padding: 10px;
  text-decoration: none;
  /* align-items: center; */
}
@media (max-width: 768px) {
  .homeBoxDiv {
    width: 100%;
  }
}
.homeBrandsMainDiv {
  width: 90%;
  height: 70vh;
  /* background-color: rgb(218, 145, 9); */
  display: flex;
  justify-content: center;
  align-items: center;
}
.homeBrandsMainDivNew {
  width: 90%;
  height: 45vh;
  /* background-color: rgb(121, 90, 32); */
  display: flex;
  justify-content: center;
  align-items: center;
}

.homeBrandsMainDivSub {
  width: 100%;
  height: 90%;
  display: flex;
  /* background-color: yellowgreen; */
  flex-direction: column;
  gap: 5%;
}
.homeBrandsMainDivSubNew {
  width: 100%;
  height: 100%;
  display: flex;
  /* background-color: yellowgreen; */
  flex-direction: column;
  gap: 5%;
}
.homeBrandsHead {
  color: rgb(0, 0, 0);
  font-size: 32px;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: 0%;
}

.homeBrandsTable {
  width: 100%;
  height: 100%;
  /* background-color: azure; */
  display: flex;
  flex-direction: column;
  gap: 5%;
}

.homeBrandsTable2 {
  width: 100%;
  height: 70%;
  /* background-color: azure; */
  display: flex;
  flex-direction: column;
  gap: 5%;
}

.homeBrandsTableRow1 {
  width: 100%;
  height: 15%;
  /* background-color: rgb(37, 125, 202); */
  display: flex;
  flex-direction: row;
  gap: 5%;
}
.homeBrandsTableRow1New {
  width: 100%;
  height: 33%;
  /* background-color: rgb(37, 125, 202); */
  display: flex;
  flex-direction: row;
  gap: 5%;
}
.homeBrandsTable2Row1 {
  width: 100%;
  height: 28%;
  /* background-color: rgb(37, 125, 202); */
  display: flex;
  flex-direction: row;
  gap: 5%;
}

.homeBrandsTableRow2 {
  width: 100%;
  height: 35%;
  /* background-color: rgb(37, 125, 202); */
  display: flex;
  flex-direction: row;
  gap: 2%;
}
.homeBrandsTableRow2American {
  width: 100%;
  height: 25%;
  /* background-color: rgb(37, 125, 202); */
  display: flex;
  flex-direction: row;
  gap: 2%;
}

.homeBrandsTableRow2New {
  width: 100%;
  height: 75%;
  /* background-color: rgb(37, 125, 202); */
  display: flex;
  flex-direction: row;
  gap: 2%;
}
.homeBrandsTable2Row2 {
  width: 100%;
  height: 65%;
  /* background-color: rgb(37, 125, 202); */
  display: flex;
  flex-direction: row;
  gap: 2%;
}

.homeBrandsTableRow1Divs {
  height: 100%;
  width: 30%;
  box-sizing: border-box;
  border: 1px solid rgb(191, 191, 191);
  border-radius: 5px;
  color: rgb(0, 0, 0);
  font-size: 24px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 36px;
  letter-spacing: 0%;
  cursor: pointer;
}

/* Define the active class */
.active {
  background: rgb(255, 154, 0);
}

.homeBrandsTableRow2Divs {
  height: 100%;
  width: 15%;
  box-sizing: border-box;
  border: 1px solid rgb(191, 191, 191);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.homeBrandsPic {
  width: 60%;
  height: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: yellow; */
  flex-direction: column;
}
.imgSpan {
  color: rgb(0, 0, 0);
}
.imgSpan2 {
  color: rgb(0, 0, 0);
  font-size: 20px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0%;
  white-space: NOWRAP;
}
.homeAfterMarketDiv {
  width: 90%;
  height: 53vh;
  /* background-color: rgb(218, 145, 9); */
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2%;
  align-items: center;
}
.homeAfterMarketSub {
  width: 100%;
  height: 100%;
  display: flex;
  /* background-color: yellowgreen; */
  flex-direction: column;
  align-items: start;
  gap: 5%;
}
.homeAfterMarketHead {
  color: rgb(0, 0, 0);
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0%;
  text-align: left;
}
.homeAfterMarketTable {
  width: 100%;
  height: 100%;
  /* background-color: rgb(37, 125, 202); */
  display: flex;
  flex-direction: column;
  gap: 5%;
}
.homeAfterMarketSeeAll {
  height: 6%;
  width: 100%;
  /* box-sizing: border-box; */
  /* border: 1px solid rgb(191, 191, 191); */
  /* border-radius: 5px; */
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  gap: 0.3%;
  cursor: pointer;
}

.homeAfterMarketSeeAllSpan:hover {
  color: rgb(255, 154, 0);
}

.homeAfterMarketSeeAllSpan {
  color: rgb(0, 0, 0);
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0%;
}

.homeFreeDiv {
  width: 100%;
  height: 10vh;
  /* background-color: aqua; */
}

@media only screen and (max-width: 768px) {
  .homeCol1Ecclipse {
    width: 50px;
    height: 50px;
  }
  .homeCol1EcclipseCircle {
    width: 60%;
    height: 60%;
  }
  .homeAboutUsHeadSub {
    font-size: 10px;
    /* width: 300px;
    height: 150px; */
  }
  .homeBrandsTableRow1Divs {
    font-size: 8px;
  }
  .homeBrandsPicImg {
    width: 70%;
    height: 40%;
  }
  .imgSpan {
    font-size: 8px;
  }
  .imgSpan2 {
    font-size: 6px;
  }

  .homeMainImgSupport {
    height: 300px;

    /* background-color: aqua; */
  }
  .homeAboutUsDivSubImg {
    /* width: 201px;
    height: 209px; */
    /* background-color: yellow; */
  }
  .homeViewMore {
    width: 44px;
    height: 10px;
    font-size: 5px;
  }
  .homeAboutUsDivSub {
    width: 90%;
  }
  .aeronSideAboutImg {
    width: 50%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .homeCol1Head {
    font-size: 16px;
  }
  .homeCol1Ecclipse {
    width: 70px;
    height: 70px;
  }
  .homeCol1EcclipseCircle {
    width: 70%;
    height: 70%;
  }
  .homeAboutUsHeadSub {
    font-size: 15px;
    /* width: 600px;
    height: 150px; */
  }
  .homeBrandsTableRow1Divs {
    font-size: 15px;
  }
  .homeBrandsPicImg {
    width: 80%;
    height: 50%;
  }
  .imgSpan {
    font-size: 12px;
  }
  .imgSpan2 {
    font-size: 12px;
  }
  .homeMainImgDiv {
    height: 500px;
  }
  .homeMainImgSupport {
    height: 500px;

    /* background-color: aqua; */
  }
  .homeAboutUsDivSubImg {
    /* width: 301px;
    height: 309px; */
    /* background-color: yellow; */
  }
  .homeViewMore {
    width: 54px;
    height: 14px;
    font-size: 7px;
  }
}
